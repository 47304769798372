import React, { useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import { AuthContext } from "./context/auth";
import PrivateRoute from "./PrivateRoute";

import "./App.scss";

import singleton from "../src/lib/singleton";
const auth=singleton.auth.getInstance();

console.log("PEPIS.APP.JS REACT_APP_APIW_URL", process.env.REACT_APP_APIW_URL)

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);


function App(props) {
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    setAuthTokens(data);
    console.log(data);
    localStorage.setItem("tokens", JSON.stringify(data));
    if (data)    auth.setJwt(data.jwt || null);
  }

  useEffect(()=>{
    function init(){
      setTokens(existingTokens);
    }
    init();
  
  },[])
  
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <UnauthenticatedRoute
              exact
              path="/login"
              name="Login Page"
              component={Login}
            />

            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </AuthContext.Provider>
  );
}

export default App;
