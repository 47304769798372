var auth = (function () {
  var instance;

  function createInstance() {
    var object = { Authorization: null };
    object.setJwt = function (jwt) {
      setJwt(jwt);
      console.log("jwt:",jwt)
    };
    return object;
  }

  function setJwt(jwt) {
    instance.jwt = jwt;
  }
  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default { auth };
